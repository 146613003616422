import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Resena from "../components/cartarese";


export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { site, markdownRemark } = data; // data.markdownRemark holds your post data
  const { siteMetadata } = site;
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <Helmet>
        <title>
          {frontmatter.title} | {siteMetadata.title}
        </title>
        <meta name="description" content={frontmatter.metaDescription} />
      </Helmet>

      <div className="blog-post-container">
        <article className="post">
          <div className="buffer"></div>
          <div className="contenedor">
            <p>Portafolio - {frontmatter.servicio}</p>
          </div>

          <section className="portafolio__encabezado">
            <div>
              <h1 className="post-title">{frontmatter.title}</h1>
              <p className="align-center" >{frontmatter.fecha}</p>
            </div>

            {!!frontmatter.thumbnail && (
              <img src={frontmatter.imagenPeq} alt="" />
            )}
          </section>
          <section className="portafolio__acerca ">
            <div className="portafolio__acerca__datos">
              <div>
                <h3 className="txt-peq2">Servicio</h3>
                <p>{frontmatter.servicio}</p>
              </div>
              <div>
                <h3 className="txt-peq2">Tecnología</h3>
                <p>{frontmatter.tecnologia}</p>
              </div>
              <div>
                <h3 className="txt-peq2">Sitio</h3>

                <a href={frontmatter.sitio}>{frontmatter.sitio}</a>
              </div>
            </div>
            <div className="portafolio__acerca__descripcion">
              <p>{frontmatter.acerca}</p>
            </div>
          </section>
          <section
            style={{
              backgroundImage: `url(${frontmatter.imagenGde})`,
            }}
            className="portafolio__img"
          ></section>
          <section className="portafolio__objetivo portafolio__acerca ">
            <div className="portafolio__acerca__datos">
              <h2 className="txt-gde">Objetivo y desafíos</h2>
            </div>
            <div className="portafolio__acerca__descripcion">
              <p>{frontmatter.objetivo}</p>
              <p>{frontmatter.desafio}</p>
            </div>
          </section>
          <section className="contenedor margenes imagen-centrada">
            <img src={frontmatter.imagenMed} alt="" />
          </section>
          <section className="portafolio__proceso portafolio__acerca ">
            <div className="portafolio__acerca__datos">
              <h2 className="txt-gde">Proceso y resultados</h2>
            </div>
            <div className="portafolio__acerca__descripcion">
              <p>{frontmatter.proceso}</p>
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </section>
          <div className="contenedor flexrow margenes">
            <Resena
              texto={frontmatter.cita}
              empresa={frontmatter.cliente}
              autor={frontmatter.autorCita}
            />
          </div>
        </article>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        thumbnail
        metaDescription
        servicio
        tecnologia
        cliente
        acerca
        objetivo
        desafio
        proceso
        cita
        imagenGde
        imagenMed
        imagenPeq
        autorCita
        fecha
        sitio
      }
    }
  }
`;
