import React from "react";

export default () => (
  <section className="servicios">
    <div className="flexcentr cincuentavh contenedor">
      <h2 className="txt-gde ">
        ESTO ES LO QUE PODEMOS HACER <span className="spanjr">POR TI</span>
      </h2>
    </div>
    <div className=" flexcentr">
      <div className="borde-top lista-grid">
        <div id="margen-top">
          <h3 className="txt-gde">Diseño</h3>
        </div>
        <ul>
          <li>Branding</li>
          <li>UI / UX</li>
        </ul>
        <ul>
          <li>Gráfico</li>
          <li>Contenido</li>
        </ul>
      </div>
      <div className="borde-top lista-grid">
        <div id="margen-top">
          <h3 className="txt-gde">Desarrollo</h3>
        </div>
        <ul>
          <li>WEB</li>
          <li>CMS</li>
        </ul>
        <ul>
          <li>eCommerce</li>
          <li>Apps</li>
        </ul>
      </div>
      <div className="borde-top lista-grid">
        <div id="margen-top">
          <h3 className="txt-gde">Marketing</h3>
        </div>
        <ul>
          <li>SEO</li>
          <li>Copywriting</li>
        </ul>
        <ul>
          <li>Ads / PPC</li>
          <li>Digital Growth</li>
        </ul>
      </div>
    </div>

   
  </section>
);
