import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";

const ContactPage = ({ data: { site } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Contacto — {site.siteMetadata.title}</title>
        <meta
          name="description"
          content={"Página de contacto de " + site.siteMetadata.description}
        />
      </Helmet>

      <div className="buffer "></div>

      <div className="contenedor">
        <form
          data-netlify="true"
          name="contacto-lasfi-studio"
          autocomplete="on"
          method="post"
          enctype="application/x-www-form-urlencoded"
          className="form-container"
          action="/gracias"
        >
          <input type="hidden" name="form-name" value="contacto-lasfi-studio" />
          <div>
            <label htmlFor="nombre">Nombre</label>
            <input type="text" name="nombre" id="nombre" />
          </div>
          <div>
            <label htmlFor="correo">Correo</label>
            <input type="email" name="correo" id="correo" />
          </div>
          <div>
            <label htmlFor="presupuesto">Presupuesto</label>
            <input type="text" name="presupuesto" id="presupuesto" />
          </div>
          <div>
            <label htmlFor="mensaje">Cuéntanos acerca de tu proyecto</label>
            <textarea name="mensaje" id="mensaje"></textarea>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <input
              type="submit"
              className="button -primary"
              style={{ marginRight: 0 }}
            />
          </div>
        </form>
      </div>
    </Layout>
  );
};
export default ContactPage;
export const pageQuery = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
