import React from "react";
import Helmet from "react-helmet";

import Home from "../components/Home";
import Home2 from "../components/Home2";
import Home3 from "../components/Home3";
import Home5 from "../components/Home5";
import Resena from "../components/cartarese";
import Layout from "../components/layout";
import Carta from "../components/Carta";
import { Link, graphql } from "gatsby";

const IndexPage = ({ data: { site } }) => {
  return (
    <Layout>
      <Helmet>
        <title>7iete</title>
        <meta name="description" content={site.siteMetadata.description} />
      </Helmet>
      <Home />
      <Home2 />
      <Home3 />
      <div className="contenedor margenes flexcentr">
        <Link to="/servicios">
          <button className="boton btn-sec raton">Ver servicios 🔍</button>
        </Link>
      </div>
      {/* <Home4 /> */}
      <Home5 />
      <section className="contenedor margenes">
        <Resena
          empresa="Floresta"
          autor="Valeria Glez."
          texto="El equipo de 7iete Studio permitió a floresta convertirse en un competidor serio dentro de nuestra industria."
        />
      </section>

      {/* <section> 
        <div className="margenes contenedor">
          <p className="span"> - Portafolio -</p>
          <h4 className="txt-med">Que nuestro trabajo hable por nosotros</h4>
        </div>
        <div className="listado__portafolio-left ">
          <Link to="/portafolio/cemeri">
            {" "}
            <Carta imagen="assets/cemeriCarta.png" nombre="CEMERI" />
          </Link>
        </div>
        <div className="listado__portafolio-right">
          <Carta imagen="assets/lucasmcas.jpg" nombre="CEMERI" />
        </div> 
      </section>
      */}
    </Layout>
  );
};

export default IndexPage;
export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
