import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Carta from "../components/Carta";
import CartaAcerca from "../components/cartaAcerca";
import Acordeon from "../components/Acordeon";

const acercaPage = ({ data: { site } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Acerca — {site.siteMetadata.title}</title>
        <meta
          name="description"
          content={"Acerca de " + site.siteMetadata.description}
        />
      </Helmet>
      <div className="responsive-movil">
        <div className="buffer "></div>

        <section className="flexcentr contenedor acerca__hero">
          <h2 className="txt-xl2 outlined ">
            Hacemos lo que <span className="outlined-inverso">mejor </span>
            sabemos hacer.{" "}
          </h2>
          <h2 className="txt-xl2 outlined ">
            <span className="outlined-inverso">No hacemos</span> lo que no
            sabemos hacer.{" "}
          </h2>

          <h2 className="txt-xl2 span">Sencillo.</h2>
        </section>
        <section>
          <div className="buffer"></div>

          {/* <div className="acerca__equipo">
            <Carta
              imagen="assets/marco.jpg"
              nombre="Marco"
              puesto="Especialista SEO"
            />
            <Carta imagen="assets/luis.jpg" nombre="Luis" puesto="Director" />
            <Carta
              imagen="assets/lorena.jpg"
              nombre="Lorena"
              puesto="Especialista Marketing"
            />
          </div> */}
        </section>
        <section>
          <h2 className="span margenes contenedor">
            ¿Cómo es trabajar con nosotros?
          </h2>
          <div className="cartas-acerca">
            <CartaAcerca
              numero="01"
              titulo="Experticia"
              descripcion="Somos apasionados de lo que hacemos y por ello nos encontramos siempre mejorando nuestro arte. Ten por seguro que estás en manos expertas."
            />

            <CartaAcerca
              numero="02"
              titulo="Empatía"
              descripcion="Sabemos que has recurrido a nosotros por algún motivo en específico. Nos aseguramos siempre de comprender cuál es tu objetivo para ayudarte a alcanzarlo"
            />

            <CartaAcerca
              numero="03"
              titulo="Más por menos"
              descripcion="Por increíble que pueda sonar, garantizamos resultados siempre. Además, encontrarás con nosotros precios competitivos y valor por igual."
            />

            <CartaAcerca
              numero="04"
              titulo="Compromiso"
              descripcion="Nos tomamos en serio nuestro trabajo. Tanto así que podría decirse que dejamos una parte de nosotros en cada proyecto que realizamos."
            />
          </div>
        </section>
        <section className="margenes">
          <h3 className="txt-xl span margenes">FAQ</h3>
          <div className="buffer-jr"></div>
          <Acordeon
            pregunta="¿Dónde se ubican sus oficinas?"
            respuesta="Atendemos de forma digital a personas del cualquier lugar y zona horaria del mundo. Aunque en su momento tuvimos oficinas en Colombia, hoy hemos trascendido a ser una empresa 100% remota."
          />
          <Acordeon
            pregunta="¿Cómo comenzó 7iete Studio?"
            respuesta="Todo comenzó el verano del '19 cuando nuestro directo cayó en cuenta de que Latinoamérica era una región con una débil presencia digital. Convencido de que el futuro lo resguarda el plano digital, decidió crear una agencia web con énfasis en el crecimiento y desarrollo. Después de unas cuantas llamadas se conformó el equipo de amigos y profesionales que hoy conforman a 7iete Studio."
          />
          <Acordeon
            pregunta="¿Por qué 7iete Studio?"
            respuesta="Por la preponderancia del número 7 dentro del ámbito digital. Por ejemplo, toma 7 segundos formar una primera impresión. Así como toma 7 exposiciones de tu marca para que sea reconocible por tu público."
          />
          <Acordeon
            pregunta="¿Por qué llevan por nombre [Studio] siendo que su público es de habla hispana?"
            respuesta="Se trata de un juego de palabras dado que siete termina en [e] y por lo tanto la palabra 'studio' pareciera tomar parte de su sonido."
          />
        </section>
      </div>
    </Layout>
  );
};

export default acercaPage;
export const pageQuery = graphql`
  query acercaPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
