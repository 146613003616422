import React from "react";
import Nav from "./Nav";
import ReactPlayer from "react-player/file";

export default (props) => (
  <section className="hero relative">
    <div className="contenedor cienvh flex-col-space ">
      <h1 className="txt-xl2 justify-txt ">
        <span>Agencia web</span> para el desarrollo de tu empresa{" "}
        <span id="asterisco">*</span>
        <sup className="txt-peq">o idea</sup>
      </h1>

      <a href="#introduccion" onMouseEnter={props.entrar} onMouseLeave={props.salir}>
        <button className="boton btn-cta">Descubre cómo</button>
      </a>
    </div>
    {/* <div className="movil">
      <ReactPlayer
        url="https://firebasestorage.googleapis.com/v0/b/lasfibank.appspot.com/o/fondoWMv1.mp4?alt=media&token=f9ab156e-f8c0-4c7e-83e2-5835cbdaba0a"
        muted
        playing
        loop
        playsinline
        width="100vw"
        height="100vh"
        className="hero-video "
      />
    </div> */}
  </section>
);
