import React from "react";
import { FaStar, FaQuoteLeft } from "react-icons/fa";

export default (props) => (
  <div className="reseña">
    <div className="comillas">
      <FaQuoteLeft />
    </div>
    <div className="estrellas">
      <FaStar />
      <FaStar />
      <FaStar />
      <FaStar />
      <FaStar />
    </div>
    <div className="texto-reseña">
      {" "}
      <p className="txt-med">{props.texto}</p>
    </div>
    <h4 className="txt-peq">{props.empresa}</h4>
    <div className="autor">{props.autor}</div>
  </div>
);
