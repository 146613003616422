import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

const Acordeon = (props) => {
  const [clicado, setClicado] = useState(false);
  const funAcordeon = () => {
    clicado ? setClicado(false) : setClicado(true);
  };

  return (
    <div onClick={funAcordeon} className="acordeon__contenedor ">
      <div className="acordeon__pregunta">
        <h4 className="txt-med raton ">{props.pregunta}</h4>
        {clicado ? (
          <FaMinus className="acordeon__icono" />
        ) : (
          <FaPlus className="acordeon__icono" />
        )}
      </div>

      {clicado ? (
        <div className="acordeon__respuesta">
          <p className=" parrafo  ">{props.respuesta}</p>
        </div>
      ) : null}
    </div>
  );
};

export default Acordeon;
