import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import PostLink from "../components/post-link";

const blogPage = ({
  data: {
    site,
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter((edge) => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map((edge) => <PostLink key={edge.node.id} post={edge.node} />);

  return (
    <Layout>
      <Helmet>
        <title>Blog — {site.siteMetadata.title}</title>
        <meta
          name="description"
          content={"Blog de " + site.siteMetadata.description}
        />
      </Helmet>

      <div className="buffer "></div>

      <section className="contenedor margenes">
        <h1 className="txt-gde margenes">7Studio Blog</h1>
        <p className="parrafo">
          ¡Consejos, tutoriales y guías sobre desarrollo, SEO y Marketing!
        </p>
      </section>
      <div className="contenedor">
        <div className="grids">{Posts}</div>
      </div>
    </Layout>
  );
};

export default blogPage;
export const pageQuery = graphql`
  query blogPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "BlogPost" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD, MMMM, YYYY")
            path
            title
            thumbnail
          }
        }
      }
    }
  }
`;
