import React from "react";
import { Link } from "gatsby";

export default () => {
  return (
    <section className="cienvh cta">
      <h3 className="txt-gde cta-txt ">¿Necesitas presencia digital?</h3>
      <h3 className="txt-xl2 plateado outlined">¡TRABAJEMOS JUNTOS!</h3>
      <Link to="/contacto" >
        <button className="btn-sqr btn-cta">Contáctanos</button>
      </Link>
    </section>
  );
};
