import React from "react";
import ReactPlayer from "react-player/file";

export default () => (
  <section className="intro contenedor" id="introduccion">
    <div className="movil margenes">
    </div>
    <div className="wrapper-z flexrow">
      <div className="intro-izq">
        <p className="span">la realidad</p>
        <h2 className="txt-xl">
          ¿Cuentas con la presencia suficiente en internet?
        </h2>
      </div>
      <div className="intro-der">
        <h3 className="txt-med paddingtop">
          Estamos en el cambio generacional
        </h3>
        <p className="parrafo">
          La
          <strong className="span"> generación Z </strong>
          realiza 75% de sus compras a través de internet y asocia la imagen
          digital con la calidad de una compañía.
        </p>

        <div className="wave-shape"></div>

        <h3 className="txt-med">Toma 7 segundos</h3>
        <p className="parrafo">
          generar una primera impresión que determinará el
          <strong className="span"> éxito  </strong> de tus objetivos
        </p>
      </div>
    </div>
  </section>
);
