import React from "react";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import {graphql} from 'gatsby';


const portafolioPage = ({ data: { site } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Portafolio — {site.siteMetadata.title}</title>
        <meta
          name="description"
          content={"Portafolio de" + site.siteMetadata.description}
        />
      </Helmet>

      <div className="buffer "></div>
    </Layout>
  );
};

export default portafolioPage;
export const pageQuery = graphql`
  query portafolioPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
