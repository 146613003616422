import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import "prismjs/themes/prism-okaidia.css";
import Footer from "./Footer";
import Nav from "./Nav";
import Cta from "./Cta";
import { FaStream } from "react-icons/fa";
import { Link } from "gatsby";


const Layout = ({ children }) => {
  const [x, setx] = useState(0);
  const [y, sety] = useState(0);

  const [radio, setradio] = useState("30px");
  const [isVisible, setIsVisible] = useState("hidden");

  const funVisible = () => {
    setIsVisible("visible");
  };

  const ocultar = () => {
    setIsVisible("hidden");
  };

  function actualizarRaton(e) {
    setx(e.clientX);
    sety(e.clientY);
    console.log(x, y);
  }

  function encogerRaton(e) {
    setradio("15px");
  }

  function regresarRaton(e) {
    setradio("30px");
  }

  function aumentarRaton() {
    setradio("60px");
  }

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );
  return (
    <div className="site-wrapper">
      <div
        onMouseMove={actualizarRaton}
        onMouseDown={encogerRaton}
        onMouseUp={regresarRaton}
      >
        <div className="cursorjs" style={{ left: x, top: y }}></div>
        <div
          className="cursorjs2"
          style={{ left: x, top: y, width: radio, height: radio }}
        ></div>
        <div className="menu-movil" id="menu" style={{ visibility: isVisible }}>
          <Nav funcion={ocultar} />

          <ul className="txt-gde">
            <li>
              <Link to="/acerca">Acerca</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <Link to="/servicios">Servicios</Link>
            </li>
            <li>
              <Link to="/contacto">Contacto</Link>
            </li>
          </ul>
        </div>

        <Nav funcion={funVisible} />
        {children}
        <Cta />
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
