import React from "react";
import Resena from "./cartarese";
import { Link } from "gatsby";
import {
  FaXingSquare,
  FaDailymotion,
  FaEtsy,
  FaGlideG,
  FaMaxcdn,
  FaCloudsmith,
} from "react-icons/fa";

export default () => (
  <section>
    <div className="contenedor">
      <Resena
        empresa="FatFetFit"
        autor="Marco Olivera"
        texto="Superaron con creces mis expectativas. No solo tienen una visión clara de qué es lo que funciona, sino que además son muy flexibles."
      />
    </div>
    <div className="empresas flexcentr responsive-movil">
      <h3 className="txt-med justify-txt">
        Empresas que han <span className="span">confíado</span> en nosotros
      </h3>
      <div className="flexrow contenedor iconos flexwrap">
        <FaXingSquare />
        <FaDailymotion />
        <FaEtsy />
        <FaGlideG />
        <FaMaxcdn />
        <FaCloudsmith />
      </div>
    </div>
    <div className="contenedor margenes ">
      <div className="un2left flexcentr">
        <p className="txt-peq span self-align-left">
          Más de 2 años de experiencia
        </p>
        <h3 className="txt-xl margenesX">
          Impulsando el desarrollo digital de latinoamérica
        </h3>
      </div>
    </div>
    <img
      className="responsive-img"
      src="https://images.unsplash.com/photo-1505409859467-3a796fd5798e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
      alt="7iete Studio"
    />

    <div className="un2der justify ">
      <p className="txt-peq parrafo ">
        7iete Studio es pionero en la <b> tecnología Jamstack</b>, la cual nos
        ha permitido presentar soluciones digitales que combinan{" "}
        <b> velocidad y desempeño </b>. Nos encanta la fama, es por ello que
        también somos expertos en <b>SEO</b>.
      </p>
      <p className="txt-peq parrafo">
        Nuestro objetivo es promover la competitividad de <b>América Latina</b>{" "}
        y a la vez esparcer nuestra identidad a lo largo del planeta. Es por
        ello que nos aseguramos siempre de dejar un pedacito de nosotros en cada
        proyecto.
      </p>
    </div>
    <Link to="/acerca">
      <button className="boton btn-sec raton">Acerca de</button>
    </Link>
  </section>
);
