import React from "react";

const ThemeChanger = () => {
  // const [tema, setTema] = useState("light");

  // function actualizarTema() {
  //   if (tema === "light") {
  //     setTema("dark");
  //     document.body.classList.add('dark');
  //     document.body.classList.remove('light');
  //   }
  //   if (tema === "dark") {
  //     setTema("light");
  //     document.body.classList.remove('dark');
  //     document.body.classList.add('light');}

  // }

  function actualizarTema() {
   
     
      document.body.classList.toggle("light");
      document.body.classList.toggle("dark");
  
     
    
  }

  return (
    <div className="mode-container" onClick={actualizarTema}>
      <i className="gg-sun"></i>
      <i className="gg-moon"></i>
    </div>
  );
};

export default ThemeChanger;
