import React, { useState } from "react";
import { Link } from "gatsby";
import ThemeChanger from "../components/themeChanger";
import { FaStream } from "react-icons/fa";

const Nav = (props) => {
  return (
    <header className="site-header">
      <div className="site-title">
        <Link to="/" className="ratoncito">
          7iete
        </Link>
      </div>
      {/* <div className="navigation">
      <Link to="/acerca">Acerca</Link>
      <Link to="/blog">Blog</Link>
      <Link to="/servicios">Servicios</Link>
    </div> */}
      <div className="flexrow nav3 site-menu">
        <ThemeChanger />
        <FaStream
          className="site-header__icono ratoncito"
          onClick={props.funcion}
        />
      </div>
    </header>
  );
};

export default Nav;
