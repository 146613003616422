import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";

const graciasPage = ({ data: { site } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Gracias — {site.siteMetadata.title}</title>
        <meta
          name="description"
          content={"Gracias" + site.siteMetadata.description}
        />
      </Helmet>
      <div className="contenedor">
        <h2 className="txt-xl">
          Gracias por tu mensaje. Pronto nos comunicaremos contigo
        </h2>
      </div>
    </Layout>
  );
};

export default graciasPage;
export const pageQuery = graphql`
  query GraciasPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
