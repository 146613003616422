import React from "react";
import { FaEnvelope, FaWhatsapp, FaBehance } from "react-icons/fa";
import { Link } from "gatsby";

export default (props) => {
  return (
    <footer className="pie-pagina flexcentr">
      <div className="extremos">
        <Link
          to="/"
          className="txt-med"
          onMouseEnter={props.entrar}
          onMouseLeave={props.salir}
        >
          {" "}
          7iete Studio
        </Link>

        <div className="self-align-right left-auto margin-interno">
          {" "}
          <a href="mailto:contacto@7iete.studio">
            {" "}
            <FaEnvelope
              color="var(--texto)"
              onMouseEnter={props.entrar}
              onMouseLeave={props.salir}
            />
          </a>
          {/*  <a href="https://api.whatsapp.com/send?phone=525542764384&text=Hola%2C%207iete%20Studio%3A%20" ><FaWhatsapp color="var(--texto)"  onMouseEnter={props.entrar} onMouseLeave={props.salir}/> </a> */}
          <FaBehance
            color="var(--texto)"
            onMouseEnter={props.entrar}
            onMouseLeave={props.salir}
          />
        </div>
      </div>
      <div className="hr margenes-jr"></div>
      <div className="extremos margin-bottom">
        <p className="txt-peq2">
          &copy; 2019 &bull; {new Date().getFullYear()} Todos los derechos
          reservados{" "}
        </p>
      </div>
    </footer>
  );
};
