import React from "react";

export default (props) => (
  <div>
    <div className="cartaServicios ">
      <div className="contenedor-jr">
        <div className="cartaAcerca__superior">
          <div className="cartaAcerca__numero">{props.numero}</div>
          <div className="txt-xl">{props.categoria}</div>
        </div>

        <ul className="lista-peq contenedor-jr">
          <li className="borde-top ">{props.serv1}</li>
          <li className="borde-top ">{props.serv2}</li>

          <li className="borde-top ">{props.serv3}</li>
          <li className="borde-top ">{props.serv4}</li>
        </ul>
      </div>
      <div>
        {/* <img src="assets/google-lasfi-studio.jpg"></img> */}
        <p className="parrafo  ">{props.descripcion}</p>
      </div>
    </div>
    <section
      
      style={{
        backgroundImage: `url(${props.imagen})`,
      }}
      className="portafolio__img"
    ></section>
  </div>
);
