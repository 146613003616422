import React from "react";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import Acordeon from "../components/Acordeon";
import CartaServicios from "../components/cartaServicios";
import { graphql } from "gatsby";

const serviciosPage = ({ data: { site } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Servicios — {site.siteMetadata.title}</title>
        <meta
          name="description"
          content={"Servicios de" + site.siteMetadata.description}
        />
      </Helmet>

      <div className="buffer "></div>

      <section className="flexcentr contenedor acerca__hero">
        <h2 className="txt-xl2 outlined ">
          Hacemos lo que <span className="outlined-inverso">mejor </span>sabemos
          hacer.{" "}
        </h2>
        <h2 className="txt-xl2 outlined ">
          <span className="outlined-inverso">No hacemos</span> lo que no sabemos
          hacer.{" "}
        </h2>

        <h2 className="txt-xl2 span">Sencillo.</h2>
      </section>
      <div className="cincuentavh flexcentr contenedor ">
        <h2 className="txt-gde    ">
          Esto es lo que podemos hacer <span className="span"> por ti</span>
        </h2>
      </div>
      <section className="flexcentr responsive-movil">
        <h3 className="txt-xl2 outlined">Marcas que perduran</h3>
        <CartaServicios
          className="responsive-movil"
          numero="01"
          categoria="Diseño"
          serv1="Branding"
          serv2="User Interface"
          serv3="User Experience"
          serv4="Contenido"
          imagen="assets/branding.jpg"
          descripcion="¿Sabías que se necesitan solo 7 segundos para crear una primera impresión de alguien? Con las marcas es exactamente lo mismo. Pero no se preocupe. Sabemos cómo crear marcas sólidas, coherentes, con valor y carácter propio (¡y que lucen muy bien, por supuesto!)."
        />

        <h3 className="txt-xl2 outlined">Sitios web de resultados</h3>

        <CartaServicios
          numero="02"
          categoria="Desarrollo"
          serv1="WEB"
          serv2="CMS"
          serv3="eCommerce"
          serv4="Apps"
          imagen="assets/multi-screen.png"
          descripcion="¿Sabías que se necesitan solo 7 segundos para crear una primera impresión de alguien? Con las marcas es exactamente lo mismo. Pero no se preocupe. Sabemos cómo crear marcas sólidas, coherentes, con valor y carácter propio (¡y que lucen muy bien, por supuesto!)."
        />
        <h3 className="txt-xl2 outlined">Estrategias para que te vean</h3>

        <CartaServicios
          numero="03"
          categoria="Marketing"
          serv1="SEO"
          serv2="Copywriting"
          serv3="Ads /PPC"
          serv4="Digital Growth"
          imagen="assets/marketing.png"
          descripcion="¿Sabías que se necesitan solo 7 segundos para crear una primera impresión de alguien? Con las marcas es exactamente lo mismo. Pero no se preocupe. Sabemos cómo crear marcas sólidas, coherentes, con valor y carácter propio (¡y que lucen muy bien, por supuesto!)."
        />
      </section>
      <section className="margenes">
        <h3 className="txt-xl span margenes">FAQ</h3>
        <div className="buffer-jr"></div>
        <Acordeon
          pregunta="¿Cuáles son los precios de sus servicios?"
          respuesta="Nuestros servicios no tienen precio fijo, en realidad varían según las características del proyecto. Si te interesa alguno de nuestros servicios (tal parece que así es dado que estás leyendo esto), contáctanos y con gusto podemos platicar de precios."
        />
        <Acordeon
          pregunta="¿Cómo es el proceso de trabajo?"
          respuesta="Lo primero siempre es agendar una cita de 'conocimiento', en la cual platicamos acerca de tu negocio o idea y de cuáles son tus objetivos. El segundo paso es la 'propuesta borrador' en la cual 7iete Studio analiza los pasos necesarios para alcanzar los objetivos discutidos en la primera etapa. En esta propuesta borrador se discuten resultados, precios, duracación y más detalles del proyecto. Una vez de acuerdo, el tercer paso es la elaboración y firma de contrato. Con la firma viene el pago de anticipo del proyecto y con él comienza el trabajo."
        />
        <Acordeon
          pregunta="Me interesa un sitio web, ¿cuánto tiempo toma elaborarlo?"
          respuesta="La duración de todo proyecto varía y corresponde con las características del mismo. Dar fechas sin conocer las características sería inadecuado, pero sí podemos decir que nuestros proyectos más rápidos han tomado una semana completarse."
        />
        <Acordeon
          pregunta="¿Si adquiero más de un servicio puedo obtener algún descuento?"
          respuesta="Nuestros precios ya son por sí mismos bastante competitivos con respecto al mercado latinoamericano y el valor que proveemos. Es por ello que no ofrecemos descuentos ni tarifas especiales"
        />
      </section>
    </Layout>
  );
};

export default serviciosPage;
export const pageQuery = graphql`
  query serviciosPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
